/*eslint-disable */
// import Spcm_milestonesModel from '../../Model/Spcm_milestones'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let spcmMilestones = {
  /**
   * spcm_milestonesList
   */
  async spcm_milestonesList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "spcm_milestones_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcm_milestonesList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * spcm_milestonesView
   */
  async spcm_milestonesView (context, spcmMilestonesId) {
    try {      
      let post_data = new FormData();
      post_data.append('ms_id', spcmMilestonesId);
      return await request.curl(context, "spcm_milestones_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at spcm_milestonesView() and Exception:",err.message)
    }    
  },

  /**
   * spcm_milestonesAdd
   */
  async spcm_milestonesAdd (context, spcm_milestonesObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcm_milestonesObj) {
        post_data.append(key, spcm_milestonesObj[key]);
      }

      return await request.curl(context, "spcm_milestones_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcm_milestonesAdd() and Exception:',err.message)
    }
  },

  /**
   * spcm_milestonesEdit
   */
  async spcm_milestonesEdit (context, spcm_milestonesObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcm_milestonesObj) {
        post_data.append(key, spcm_milestonesObj[key]);
      }

      return await request.curl(context, "spcm_milestones_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcm_milestonesEdit() and Exception:',err.message)
    }
  },

  /**
   * spcm_milestonesDelete
   */
  async spcm_milestonesDelete (context, spcmMilestonesId) {
    try{
      let post_data = new FormData();
      
      post_data.append('ms_id', spcmMilestonesId);

      return await request.curl(context, "spcm_milestones_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at spcm_milestonesDelete() and Exception:',err.message)
    }
  }
}

export {
  spcmMilestones
}
